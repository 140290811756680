<template>
  <div>
    <input
      type="text"
      class="form-control form-control-sm"
      :id="passedData.name + '_' + passedData.index"
      :placeholder=passedData.placeHolder
      v-model="newValue"
      @change="changeValue()"
    />
  </div>
</template>

<script>

export default {
  name: 'textInputField',
  created: async function () {
    this.newValue = this.passedData.value;
  },
  data: function () {
    return {
      newValue: this.passedData.value,
    }
  },
  methods: {
    changeValue: function() {
      if (this.passedData.value != this.newValue) {
        var returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.newValue,
          placeHolder: this.passedData.placeHolder,
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
  },
  props: ['passedData'],
}

</script>
