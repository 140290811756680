import HTTP from './HttpService'

var PickList = {

  getPickLists: async function () {
    var pickLists = [];

    await HTTP.http().get('/picklist')
      .then(function(response) {
        if (response) {
          pickLists = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load pickLists error: ' + error);
      });

    return pickLists;
  },

}

export default PickList;