<template>
  <div>
    <div v-for="(trueFalse) in trueFalseValues" :key="trueFalse.id" class="form-check form-check-inline">
      <input type="radio" v-model="newValue" :name="passedData.name + '_' + passedData.index" :value="trueFalse.id" @change="changeValue()"/>
      <label class="form-check-label form-control-sm" style="padding-top: 6px;" :for="passedData.name + '_' + passedData.index">
        {{trueFalse.value}}
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'booleanInputField',
  data: function () {
    return {
      newValue: this.passedData.value,
      trueFalseValues: [
        {
          id: "false",
          value: "No",
        },
        {
          id: "true",
          value: "Yes",
        },
      ],
    }
  },
  methods: {
    changeValue: function() {
      if (this.passedData.value != this.newValue) {
        var returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.newValue,
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
  },
  props: ['passedData'],
}

</script>
