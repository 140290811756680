<template>

  <div class="container">
    <h5>Pick List Administration</h5>

    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-3">
              <select class="custom-select custom-select-sm" v-model="pickListId" id="pickList" @change="selectPickList()">
                <option
                  v-for="(pickList) in pickLists"
                  :key="pickList.id"
                  :value="pickList.id"
                >{{pickList.lookupKey}}
                </option>
              </select>
          </div>
          <div class="col-4">
              <label>{{(pickListId > 0) ? pickList.description : "Select a pick list from the pulldown menu"}}</label>
          </div>
          <div class="col-3">
            <input v-if="pickListId > 0" id="filter" placeholder="Filter Name, Value or Description" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div v-if="isAdmin && pickListId > 0" class="col-2" style="text-align:right;">
            <div class="custom-cursor-pointer mt-2" @click="addPickDetail()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add Record</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Value</th>
            <th scope="col">Description</th>
            <th class="col-1 small" scope="col">Weight</th>
            <th scope="col">Default Value</th>
            <th scope="col">Non Standard</th>
            <!-- Only for Application Status -->
            <th v-if="pickListId === 9" scope="col">Active</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(pickDetail, index) in filteredResult" :key="pickDetail.id">
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'name', index: index, value: pickDetail.name}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{pickDetail.name}}</label>
            </td>
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'value', index: index, value: pickDetail.value}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{pickDetail.value}}</label>
            </td>
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'description', index: index, value: pickDetail.description}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{pickDetail.description}}</label>
            </td>
            <td>
              <IntegerInputField v-if="isAdmin" :passedData="{name: 'weight', index: index, value: pickDetail.weight}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{pickDetail.weight}}</label>
            </td>
            <td>
              <BooleanInputField v-if="isAdmin" :passedData="{name: 'defaultValue', index: index, value: pickDetail.defaultValue}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{(pickDetail.defaultValue) ? "Yes" : "No"}}</label>
            </td>
            <td>
              <BooleanInputField v-if="isAdmin" :passedData="{name: 'nonStandard', index: index, value: pickDetail.nonStandard}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{(pickDetail.nonStandard) ? "Yes" : "No"}}</label>
            </td>
            <td v-if="pickListId === 9">
              <BooleanInputField v-if="isAdmin" :passedData="{name: 'active', index: index, value: pickDetail.active}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{(pickDetail.active) ? "Yes" : "No"}}</label>
            </td>
            <td class="text-right">
              <i v-if="isAdmin" class="fa fa-trash" aria-hidden="true" @click="deletePickDetail(pickDetail)" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'

import Store from '@/store'

import BooleanInputField from '@/components/util/BooleanInputField'
import IntegerInputField from '@/components/util/IntegerInputField'
import TextInputField from '@/components/util/TextInputField'

import PickDetail from '@/services/PickDetailService'
import PickList from '@/services/PickListService'

export default {
  name: 'pickLists',
  components: {
    BooleanInputField,
    IntegerInputField,
    TextInputField
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.pickDetailList.filter(result => {
        return (
          result.name.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.value.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.description.toLowerCase().match(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  created: async function () {
    this.pickLists = await PickList.getPickLists();
  },
  data: function () {
    return {
      pickDetailList: [],
      pickList: {},
      pickListId: 0,
      pickLists: [],
      searchTerm: "",
    }
  },
  methods: {
    addPickDetail: async function() {
      if (this.pickListId > 0) {
        var pickDetail =
          {
            pickListId: this.pickListId,
            name: 'New Record',
            value: 'New Record',
            description: 'Update Me',
            weight: 0,
            defaultValue: false,
            nonStandard: false
          }

        pickDetail = await PickDetail.createPickDetail(pickDetail);
        this.pickDetailList.push(pickDetail);
      }
    },
    changeFromComponent: function(returnData) {
      var name = returnData.name;
      var idx = returnData.index;
      var pickDetail = this.pickDetailList[idx];
      var newValue = returnData.newValue;

      pickDetail[name] = newValue;
      this.changePickDetail(pickDetail);
    },
    changePickDetail: async function(pickDetail) {
      var idx = lodash.findIndex(this.pickDetailList, {id: pickDetail.id});

      this.pickDetailList[idx] = await PickDetail.updatePickDetail(pickDetail);
    },
    deletePickDetail: function(pickDetail) {
      PickDetail.deletePickDetail(pickDetail.id);
      this.pickDetailList = lodash.without(this.pickDetailList, pickDetail);
    },
    selectPickList: async function() {
      var idx = lodash.findIndex(this.pickLists, {id: this.pickListId});
      this.pickList = this.pickLists[idx];
      this.pickDetailList = await PickDetail.getPickListDetailByPickListId(this.pickListId);
    }
  }
}
</script>
